import React from 'react';



import swipone from '../../Assets/Swipper/1.jpg';
import swiptwo from '../../Assets/Swipper/2.jpg';
import swipthree from '../../Assets/Swipper/3.jpg';
import swipfour from '../../Assets/Swipper/4.jpg';
import swipfive from '../../Assets/Swipper/5.jpg';
// import swipsix from '../../Assets/Swipper/6.jpg';
import swipseven from '../../Assets/Swipper/7.jpg';
import swipeight from '../../Assets/Swipper/8.jpg';
import swipnine from '../../Assets/Swipper/9.jpg';
import swipten from '../../Assets/Swipper/10.jpg';
import swipeleven from '../../Assets/Swipper/11.jpg';


import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';


const Slider = () => {
    return (
       <div className='container p-3'>
        <h1 className='pt-4'>Glimpse of Projects</h1>
                <div className='row'>
                <Swiper className=' z-n1'
                  modules={[Navigation, Autoplay]}
                  autoplay={true}
                    breakpoints={{
                   0:{
                    spaceBetween:30,      
                    slidesPerView: 1,
                   },
                   480: {
                    spaceBetween:30,
                    slidesPerView: 2,
                   },
                   768: {
                    spaceBetween:30,
                    slidesPerView: 3,
                   },
                   1024: {
                    spaceBetween:30,
                    slidesPerView: 4,
                   },
                }}
                // navigation
                pagination={{ clickable: true }}
                >
                <SwiperSlide>
                    <img className="w-100 " src={swipone} />
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-100 " src={swiptwo} />
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-100 " src={swipthree} />
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-100 " src={swipfour} />
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-100 " src={swipfive} />
                </SwiperSlide>
                {/* <SwiperSlide>
                    <img className="w-100 " src={swipsix} />
                </SwiperSlide> */}
                <SwiperSlide>
                    <img className="w-100 " src={swipseven} />
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-100 " src={swipeight} />
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-100 " src={swipnine} />
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-100 " src={swipten} />
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-100 " src={swipeleven} />
                </SwiperSlide>
                </Swiper>
                </div>
                {/* <div className='row d-flex justify-content-center mt-3'>
                            <button type="button" class=" button-color mb-5  w-25 " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">
                            Schedule a visit
                            </button>
                </div> */}
        </div>
    );
};

export default Slider;