import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import logoss from '../../Assets/logo kns and ananta.png';
import'./Footersecond.css';
import PhoneNumberLink from '../PhoneNumberLink/PhoneNumberLink';

const Footersecond = () => {
    return (
        <div className='background-colouring'>
        <div className='row'>
            <div className='col-lg-4 col-md-12 p-4'>
                <img src={logoss} className='w-50' alt=''/>   
            </div>
            <div className='col-lg-8 col-md-12 p-4'>
            {/* <ul class="navbar-nav d-flex flex-row font-sizer  justify-content-center ">
                <li class="nav-item me-2">
                <Link class="nav-link text-light active" aria-current="page" smooth to="/home#home" >HOME</Link>
                </li>
                <li class="nav-item me-2">
                <Link smooth to="/home#about" class="nav-link text-light" >ANANTA</Link>
                </li>
                <li class="nav-item me-2">
                <Link class="nav-link text-light" smooth to="/home#masterplan">MASTERPLAN</Link>
                </li>
                <li class="nav-item">
                <Link class="nav-link text-light" to='/clubhouse'>CLUB HOUSE</Link>
                </li>
                <li class="nav-item me-2">
                <Link class="nav-link text-light" smooth to="/home#amenities">AMENITIES</Link>
                </li>
                <li class="nav-item me-2">
                <Link class="nav-link text-light" smooth to="/home#location">LOCATION</Link>
                </li>
                <li class="nav-item me-2">
                <Link class="nav-link text-light" smooth to="/home#contactus">CONTACT US</Link>
                </li>                  
                
            </ul> */}
            <h6 className='text-light'> PRM/KA/RERA/1251/310/PR/220623/006012</h6>

            <div>
                <p className='text-light'>Call Us Today : </p>
                <PhoneNumberLink phoneNumber="8880 66 33 44" />
                <PhoneNumberLink phoneNumber="8880 66 22 33" />
                </div>
            <div>
                <p className='text-light'>copyright @ reserved by KNS Infrastructure Private Limited</p>
            </div>
            </div>
        </div>
    </div>
    );
};

export default Footersecond;