import React from 'react';
import location from '../../Assets/mapananta.webp';


const Location = () => {
    return (
        <div className='container my-5 '>
            <div className='row'>
            <div className='col-lg-12'>
                 <h2 className='text-dark'>Location</h2>
                <a href='https://goo.gl/maps/iVdLRG5gNKC7fD7o7' target='_blank'><img src={location} className='w-100' alt=''/> </a>
            </div>
            
            </div>
        </div>
    );
};

export default Location;