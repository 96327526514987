import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import logonav from '../../Assets/anantalogo.png';
import icon from '../../Assets/menuicon.png';

const Navsep = () => {
    return (
        <div>
            <div className='position-fixed w-100'>
            <nav class="navbar navbar-expand-lg  background-colour shadow ">
            <div class="container-fluid">
            <Link class="navbar-brand ms-3" href="#"> 
            <img src={logonav} alt="Bootstrap" className='width-log'/>
            </Link>
                <button class="menu  bg-none color-icon background-colour " type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class=" color-icon "><img src={icon} alt='logoicon' className='w-75'/></span>
                </button>
                <div class="collapse navbar-collapse " id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                    <Link class="nav-link text-light active" aria-current="page" smooth to="/home#home" >HOME</Link>
                    </li>
                    <li class="nav-item">
                    <Link smooth to="/home#about" class="nav-link text-light" >ANANTA</Link>
                    </li>
                    <li class="nav-item">
                    <Link class="nav-link text-light" smooth to="/home#masterplan">MASTERPLAN</Link>
                    </li>
                    <li class="nav-item">
                    <Link class="nav-link text-light" to='/clubhouse'>CLUB HOUSE</Link>
                    </li>
                    <li class="nav-item">
                    <Link class="nav-link text-light" smooth to="/home#amenities">AMENITIES</Link>
                    </li>
                    <li class="nav-item">
                    <Link class="nav-link text-light" smooth to="/home#gallary">GALLARY</Link>
                    </li>
                    <li class="nav-item">
                    <Link class="nav-link text-light" smooth to="/home#location">LOCATION</Link>
                    </li>
                    <li class="nav-item">
                    <Link class="nav-link text-light" smooth to="/home#contactus">CONTACT US</Link>
                    </li>                  
                    
                </ul>
                
                </div>
            </div>
            </nav>
            </div>
        </div>
    );
};

export default Navsep;