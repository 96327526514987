import React from 'react';
import './NavbarHead.css';
import { HashLink as Link } from 'react-router-hash-link';
import logonav from '../../Assets/anantalogo.png';
import Home from '../Home/Home';
import Formmailer from '../Formmailer/Formmailer';
import AboutAnanta from '../AboutAnanta/AboutAnanta';
import Amenities from '../Amenities/Amenities';
import Location from '../Location/Location';
import ContactUs from '../ContactUs/ContactUs';
import Footersetup from '../FooterSetup/Footersetup';
import Masterplan from '../Materplan/Masterplan';
import icon from '../../Assets/menuicon.png';
import ProjectDetails from '../ProjectDetails/ProjectDetails';
import Slider from '../Slider/Slider';



const NavbarHead = () => {
    return (
        <div>
            <div className='position-fixed w-100'>
            <nav class="navbar navbar-expand-lg  background-colour shadow ">
            <div class="container-fluid">
            <Link class="navbar-brand ms-3" href="#"> 
            <img src={logonav} alt="Bootstrap" className='width-log'/>
            </Link>
                <button class="menu  bg-none color-icon background-colour" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class=" color-icon "><img src={icon} alt='logoicon' className='w-75'/></span>
                </button>
                <div class="collapse navbar-collapse " id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                    <Link class="nav-link text-light active" aria-current="page" smooth to="#home" >HOME</Link>
                    </li>
                    <li class="nav-item">
                    <Link smooth to="#about" class="nav-link text-light" >ANANTA</Link>
                    </li>
                    <li class="nav-item">
                    <Link class="nav-link text-light" smooth to="#masterplan">MASTERPLAN</Link>
                    </li>
                    <li class="nav-item">
                    <Link class="nav-link text-light" to='/clubhouse'>CLUB HOUSE</Link>
                    </li>
                    <li class="nav-item">
                    <Link class="nav-link text-light" smooth to="#amenities">AMENITIES</Link>
                    </li>
                    <li class="nav-item">
                    <Link class="nav-link text-light" smooth to="#gallary">GALLARY</Link>
                    </li>
                    <li class="nav-item">
                    <Link class="nav-link text-light" smooth to="#location">LOCATION</Link>
                    </li>
                    <li class="nav-item">
                    <Link class="nav-link text-light" smooth to="#contactus">CONTACT US</Link>
                    </li>                  
                    
                </ul>
                
                </div>
            </div>
            </nav>
            </div>
            

            <div id="home"><Home></Home> </div>
            <div> <Formmailer></Formmailer></div>
            <div id="about">
            <AboutAnanta></AboutAnanta>
            </div>
            <div id="masterplan">
            <Masterplan></Masterplan>
            </div>
            <div id="masterplan">
            <ProjectDetails></ProjectDetails>
            </div>
            <div id="amenities">            
            <Amenities></Amenities>
            </div>
            <div id="gallary">
            <Slider></Slider>
            </div> 
            <div  id="location">
            <Location></Location>
            </div>
            <div id="contactus">
            <ContactUs></ContactUs>
            </div>
            <div id="footer">
            <Footersetup></Footersetup>
            </div>
            
            
            
        </div>
    );
};

export default NavbarHead;