
import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import NavbarHead from './Component/Navbar/NavbarHead';
import ClubHouse from './Component/Clubhouse/ClubHouse';
import ComingSoon from './Component/ComingSoon/ComingSoon';


function App() {

  const router = createBrowserRouter([
    {path:'/', element: <NavbarHead></NavbarHead>},
    {path:'*', element: <NavbarHead></NavbarHead>},
    {path:'/home', element: <NavbarHead></NavbarHead>},
    {path:'/clubhouse', element: <ClubHouse></ClubHouse>},
    {path:'/comingsoon', element: <ComingSoon></ComingSoon>},
    

   
   

  ])
 
  return (
    <div className="App">
       <div className="">
    <RouterProvider router={router}></RouterProvider>
    
    </div>
    </div>
  );
}

export default App;
