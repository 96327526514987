import React from 'react';
import './AboutAnanta.css';


const AboutAnanta = () => {
    return (
        <div >
            <h2 className='colorised-font my-5'>About Ananta</h2> 
            <p className=' my-5 colorised-font for-mob'>
            New day brings many choices and when your<br/>
            environment supports those limitless choices,<br/>
            you find yourself pleasantly surprised.<br/>
            Welcome to Ananta, it’s the place where <br/>
            inspiration takes on limitless forms to foster <br/>
            a lifestyle that caters to your full expression. <br/>
            Whether it's the soothing act of sitting still <br/>
            as you watch the sunrise gleam over the<br/>
            myriad shades of the terraced gardens<br/>
            or choose to enjoy a game of tennis<br/><br/><br/>

            it’s all possible.
            </p>


            <p className=' my-5 colorised-font for-web'>
            New day brings many choices and when your
            environment supports those limitless choices,<br/>
            you find yourself pleasantly surprised.
            Welcome to Ananta, it’s the place where 
            inspiration takes on limitless forms to foster 
            a lifestyle that caters to your full expression. <br/>
            Whether it's the soothing act of sitting still
            as you watch the sunrise gleam over the
            myriad shades of the terraced gardens
            or choose to enjoy a game of tennis<br/><br/><br/>

            it’s all possible.
            </p>
            
        </div>
    );
};

export default AboutAnanta;