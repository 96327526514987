import React from 'react';
import Navsep from '../NavSep/Navsep';
import Footersetup from '../FooterSetup/Footersetup';
import comweb from '../../Assets/comingweb.jpg';
import commob from '../../Assets/cominmob.jpg';

const ComingSoon = () => {
    return (
        <div>
              <Navsep></Navsep>
              <img src={comweb} className='w-100 changerweb' alt='background-photo'/>
              <img src={commob}  className='w-100 changermob' alt='background-photo'/>
              <Footersetup></Footersetup>
        </div>
    );
};

export default ComingSoon;