import React from 'react';
import './Home.css';
import backweb from '../../Assets/web pc.jpg';
import backmob from '../../Assets/Website.webp';
import Modaler from '../Modaler/Modaler';




const Home = () => {
    return (
        <div>
            
            <div> 
            
                <img src={backweb} className='w-100 changerweb' alt='background-photo'/>
                <img src={backmob}  className='w-100 changermob' alt='background-photo'/>

            </div>

           <Modaler></Modaler>
             
        </div>
    );
};

export default Home;