import React from 'react';
import masterplan from '../../Assets/blueprint.png';
import { Link } from 'react-router-dom';
import './Masterplan.css';

const Masterplan = () => {
    return (
        <div >
            <img src={masterplan}  className='widthsetup mt-4' alt=''/> 
            <div class="col-ms-auto my-4" >
                   <Link to='https://ananta-showcase-lite.azurewebsites.net/'><button type="submit" class="btn-setup">View Master Plan</button></Link> 
            </div>
        </div>
    );
};

export default Masterplan;