import React from 'react';
import clubweb from '../../Assets/clubhousepage.jpg';
import clubmob from '../../Assets/clubhousepagemob.jpg';


import Navsep from '../NavSep/Navsep';
import Footersecond from '../FooterSecond/Footersecond';

const ClubHouse = () => {
    return (
        <div>
            <Navsep></Navsep>
              <img src={clubweb} className='w-100 changerweb' alt='background-photo'/>
              <img src={clubmob}  className='w-100 changermob' alt='background-photo'/>
              <Footersecond></Footersecond>
        </div>
    );
};

export default ClubHouse;