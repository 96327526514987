import React, { useState } from 'react';
import Swal from 'sweetalert2';
import emailjs from '@emailjs/browser';
import './Contactus.css';

const ContactUs = () => {

    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const [isChecked, setIsChecked] = useState(false);


    const [formData, setFormData] = useState({

        
        name: '',
        email: '',
        number: '',

    })
    const sendEmail = (e) => {
        e.preventDefault();
        setButtonDisabled(true);

        if(formData.name && formData.email && formData.number){
          emailjs.sendForm('service_v73c39b', 'template_r6814o1', e.target, 'Q_tnUooVuTOT2eItg')
            // emailjs.sendForm('service_z652qps', 'template_zw98f2n', e.target, 'BrVdjJ_dP2sCEqW45')
            .then((result) => {
                // alert('Thank You! We reach you soon!');
                // Swal.fire(
                //     'Thank You!',
                //     'We will reach you soon',
                //     'success'
                //   );

                // navigate('https://thankyoupage.knsgroup.in/');
                window.location.href = 'https://thankyou.knsananta.com/';
  
                  setFormData({
                    
                    name: '',
                    email: '',
                    number: '',
                  });
                  
            }, (error) => {
                // alert('Server Issus! Not Submitted');
                Swal.fire(
                    'Oops!',
                    'Somethings Wrong, Please Try Again',
                    'error'
                  );
                  setFormData({
                     
                      name: '',
                      email: '',
                      number: '',
                  });                 
            });
            
        }
        else{
            Swal.fire(
                'Oops!',
                'Please Fill the details',
                'info'
              );
        }
        e.target.reset();
        
      };
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
      };


    return (
        <div className='container  ' >
           <form onSubmit={sendEmail}> 
           <div class="row g-3 my-5 background-colorized rounded p-2 m-2">
                <div class="col-lg-4 col-md-12">
                    <input type="text" class="form-control" placeholder="Name" aria-label="City" name='name' value={formData.name} onChange={(event) => setFormData({ ...formData, name: event.target.value })}/>
                </div>
                <div class="col-lg-4 col-md-12">
                    <input type="number"   class="form-control" placeholder="Contact No" aria-label="State" name='number' value={formData.number} onChange={(event) => setFormData({ ...formData, number: event.target.value })}/>
                </div>
                <div class="col-lg-4 col-md-12">
                    <input type="email"  class="form-control" placeholder="Email" aria-label="Zip" name='user_email' value={formData.email} onChange={(event) => setFormData({ ...formData, email: event.target.value })}/>
                </div>
                <div class="form-check d-flex justify-content-start  ">
                                <input class="form-check-input   me-2 " type="checkbox" value="" id="flexCheckDefault" checked={isChecked} onChange={handleCheckboxChange}/>
                                <label class="form-check-label text-dark" for="flexCheckDefault">
                                    Accept all Terms & Conditions
                                </label>
                                </div>
                <div class="col-ms-auto">
                    <button type="submit" class="btn-setup" disabled={!isChecked || isButtonDisabled}  >Submit</button>
                </div> 
                
                </div> 
           </form>
                         
        </div>
    );
};

export default ContactUs;