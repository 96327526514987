import React from 'react';
import './Phonenumber.css';

const PhoneNumberLink = ({ phoneNumber }) => {
  const handlePhoneNumberClick = () => {
    // Construct the tel: link
    const telLink = `tel:${phoneNumber}`;
    
    // Open the phone dialer
    window.location.href = telLink;
  };

  return (
    <a className='designer ' href="#" onClick={handlePhoneNumberClick}>
      {phoneNumber}
    </a>
  );
};

export default PhoneNumberLink;
